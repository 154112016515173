import { Promotion } from '../../interfaces/products/promotion'

interface ReducerState {
  loading: boolean
  items?: Promotion[]
  futurPrice?: any
  currentPromotion?: Promotion
  error?: any
}

export const initialProductsState: ReducerState = { loading: false }

const promotionsReducer = (
  state = initialProductsState,
  action
): ReducerState => {
  switch (action.type) {
    case 'GET_PROMOTIONS_REQUEST':
      return { ...state, loading: true }
    case 'GET_PROMOTIONS_SUCCESS':
      return { ...state, loading: false, items: action.promotions }
    case 'SET_FUTUR_PRICE':
      return { ...state, loading: false, futurPrice: action.price }
    case 'SET_CURRENT_PROMOTION':
      return {
        ...state,
        loading: false,
        currentPromotion: action.currentPromotion,
      }
    case 'GET_PROMOTIONS_FAILURE':
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export default promotionsReducer
