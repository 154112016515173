import { AssociatedProductAdapter } from './associatedProductAdapter'
import { PackAssociation } from '../../../interfaces/packs/packAssociationInterface'

export const PackAssociationAdapter = (association): PackAssociation => {
  return {
    products: association?.associatedProducts?.map((associatedProduct) =>
      AssociatedProductAdapter(associatedProduct)
    ),
  }
}
