import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import dayjs from 'dayjs'
import setDateFnDefaultOptions from 'date-fns/setDefaultOptions'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { registerLocale } from 'react-datepicker'

dayjs.extend(localizedFormat)

export const loadDateFnsLocale = async (locale) => {
  return await import(`date-fns/locale/${locale}/index.js`)
}

function loadDayjsLocale(lng) {
  const locale = lng === 'en-US' ? 'en' : lng.toLowerCase()
  import(`dayjs/locale/${locale}`)
    .then(() => {
      dayjs.locale(lng)
    })
    .catch((err) => {
      console.error(`Could not load Day.js locale for language: ${locale}`, err)
    })
}

const i18NextLanguageChange = (router) => async () => {
  const { locale } = router
  let lang = locale
  if (locale === 'en') {
    const en_Region = process.env.NEXT_PUBLIC_EN_REGION
    lang = `${locale}-${en_Region && en_Region !== 'GB' ? en_Region : 'GB'}`
  }

  loadDayjsLocale(lang)

  const data = await loadDateFnsLocale(lang)
  if (data) {
    setDateFnDefaultOptions({ locale: data })
    registerLocale(lang, data)
  } else {
    console.error(`Could not load date-fns locale for language: ${lang}`)
  }
}

export const i18nextInit = async (router, i18nResources) => {
  if (!i18nResources) {
    return
  }

  const { translations, namespaces } = i18nResources
  if (!i18next.isInitialized) {
    i18next.use(initReactI18next).init({
      lng: router.locale,

      supportedLngs: router.locales,

      fallbackLng: router.defaultLocale,

      ns: namespaces,

      react: {
        useSuspense: false,
      },
    })
  }

  i18next.setDefaultNamespace(namespaces[0])

  if (i18next.language !== router.locale) {
    i18next.changeLanguage(router.locale, i18NextLanguageChange(router))
  }

  namespaces.forEach((ns) => {
    if (!i18next.hasResourceBundle(router.locale, ns)) {
      i18next.addResourceBundle(router.locale, ns, translations[ns])
    }
  })
}

export async function getTranslations(locale, namespaces) {
  const translations = {}
  const shortLocale = locale.substring(0, 2)

  for (const ns of namespaces) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const data = require(`./public/static/locales/${shortLocale}/${ns}.json`)

    translations[ns] = data
  }

  return { translations, namespaces }
}
