import { PackAssociationAdapter } from './packAssociationAdapter'
import { PackInterface } from '../../../interfaces/packs/packInterface'

export const PackAdapter = (pack): PackInterface => {
  return {
    id: pack?.id,
    name: pack?.name,
    code: pack?.code,
    description: pack?.description,
    images: pack?.images,
    packProducts: pack?.packProducts,
    slug: pack?.slug,
    productsAssociations:
      pack?.associations?.length > 0
        ? pack?.associations
            ?.filter(
              (association) =>
                association &&
                Object.keys(association).includes('associatedProducts')
            )
            .map((associatedProduct) =>
              PackAssociationAdapter(associatedProduct)
            )
        : [],
    //in order to handle associated packs
    // packsAssociations: pack?.associations.filter(
    //   (association) =>
    //     association && Object.keys(association).includes('associatedPacks')
    // ),
  }
}
