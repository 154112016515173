import React from 'react'
import { makeStyles, createStyles, Typography, Card } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import WarningIcon from '@material-ui/icons/Warning'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) =>
  createStyles({
    snack: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
    },
    checkIcon: {
      color: theme.palette.primary.contrastText,
      paddingRight: theme.spacing(0.5),
    },
    warningIcon: {
      color: theme.palette.primary.contrastText,
      paddingRight: theme.spacing(0.5),
    },
    cancelIcon: {
      color: theme.palette.primary.contrastText,
      cursor: 'pointer',
      paddingLeft: theme.spacing(0.5),
    },
    underline: {
      //textDecoration: 'underline',
      cursor: 'pointer',
    },
  })
)

const InformationChip = React.forwardRef((props: any, ref) => {
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()
  return (
    <Card ref={ref}>
      <Typography variant="body2" className={classes.snack}>
        {props.message && props.message.message === 'add' && (
          <>
            <CheckCircleIcon className={classes.checkIcon} />
            <span>
              {props.i18n.texts['snackbar_message'].add}&nbsp;
              <span
                className={classes.underline}
                onClick={() => document.getElementById('menu-toggler')?.click()}
              >
                <b>{props.i18n.texts['snackbar_message'].cart}</b>
              </span>{' '}
              !
            </span>
          </>
        )}
        {props.message && props.message.message === 'delete' && (
          <>
            <WarningIcon className={classes.warningIcon} />
            <span>
              {props.i18n.texts['snackbar_message'].delete}&nbsp;
              <span
                className={classes.underline}
                onClick={() => document.getElementById('menu-toggler')?.click()}
              >
                <b>{props.i18n.texts['snackbar_message'].cart}</b>
              </span>{' '}
              !
            </span>
          </>
        )}
        {props.message && props.message.message === 'remove' && (
          <>
            <CheckCircleIcon className={classes.checkIcon} />
            <span>
              {props.i18n.texts['snackbar_message'].remove}&nbsp;
              <span
                className={classes.underline}
                onClick={() => document.getElementById('menu-toggler')?.click()}
              >
                <b>{props.i18n.texts['snackbar_message'].cart}</b>
              </span>{' '}
              !
            </span>
          </>
        )}
        {props.message && props.message.message === 'add-multiple' && (
          <>
            <CheckCircleIcon className={classes.checkIcon} />
            <span>
              {props.i18n.texts['snackbar_message'].add_multiple}&nbsp;
              <span
                className={classes.underline}
                onClick={() => document.getElementById('menu-toggler')?.click()}
              >
                <b>{props.i18n.texts['snackbar_message'].cart}</b>
              </span>{' '}
              !
            </span>
          </>
        )}
        {props.message && props.message.message === 'limit' && (
          <>
            <WarningIcon className={classes.warningIcon} />
            <span>{props.i18n.texts['snackbar_message'].limit}&nbsp;</span>
          </>
        )}
        {props.message && props.message.message === 'max' && (
          <>
            <WarningIcon className={classes.warningIcon} />
            <span>{props.i18n.texts['snackbar_message'].max}&nbsp;</span>
          </>
        )}
        {props.message && props.message.message === 'add tryout' && (
          <>
            <CheckCircleIcon className={classes.checkIcon} />
            <span>{props.i18n.texts['snackbar_message'].add_tryout}&nbsp;</span>
          </>
        )}
        <CancelIcon
          className={classes.cancelIcon}
          onClick={() => closeSnackbar(props.id)}
        />
      </Typography>
    </Card>
  )
})

export default InformationChip
