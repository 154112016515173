export const initialProductsState = { loading: false }

const orderReducer = (state = initialProductsState, action) => {
  switch (action.type) {
    case 'POST_PRODUCT_TO_RETURN_REQUEST':
    case 'GET_ORDER_INVOICE_REQUEST':
      return { ...state, loading: true }
    case 'SAVE_PRODUCT_TO_RETURN_SUCCESS':
      return { ...state, loading: false, items: action.response }
    case 'POST_PRODUCT_TO_RETURN_SUCCESS':
      return {
        ...state,
        loading: false,
        pdfResponse: action.response,
        hasReturned: true,
      }
    case 'GET_ORDER_INVOICE_SUCCESS':
      return {
        ...state,
        loading: false,
        invoice: action.invoice,
      }
    case 'POST_PRODUCT_TO_RETURN_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
        hasReturned: false,
      }
    case 'GET_USER_INVOICE_FAILURE': {
      return {
        ...state,
        loading: false,
        error: action.err,
      }
    }
    default:
      return state
  }
}

export default orderReducer
