interface ReducerState {
  loading: boolean
  items?: any
  error?: any
}

export const initialProductsState: ReducerState = { loading: false }

const addressUserReducer = (
  state = initialProductsState,
  action
): ReducerState => {
  switch (action.type) {
    case 'GET_ADDRESS_USER_REQUEST':
    case 'POST_ADDRESS_USER_REQUEST':
    case 'PUT_ADDRESS_USER_REQUEST':
      return { ...state, loading: true }
    case 'GET_ADDRESS_USER_SUCCESS':
    case 'POST_ADDRESS_USER_SUCCESS':
    case 'PUT_ADDRESS_USER_SUCCESS':
      return { ...state, loading: false, items: action.address }
    case 'GET_ADDRESS_USER_FAILURE':
    case 'POST_ADDRESS_USER_FAILURE':
    case 'PUT_ADDRESS_USER_FAILURE':
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export default addressUserReducer
