import { ProductVariantAdapter } from '../products/productVariantAdapter'
import { AssociatedProduct } from '../../../interfaces/packs/associatedProductInterface'

export const AssociatedProductAdapter = (product): AssociatedProduct => {
  return {
    id: product?.id,
    name: product?.name,
    slug: product?.slug,
    code: product?.code,
    images: product?.images,
    description: product?.description,
    shortDescription: product?.shortDescription,
    averageRating: product?.averageRating,
    associations: product?.associations,
    enabled: product?.enabled,
    isSimpleProduct: product?.isSimpleProduct,
    variants: convertArrayToObject(
      product?.variants.map((variant) => ProductVariantAdapter(variant)),
      'code'
    ),
  }
}
const convertArrayToObject = (array, key) => {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    }
  }, initialValue)
}
