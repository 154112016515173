import { Product } from '../../typings/base'

interface ReducerState {
  item?: Product
  loading: boolean
  error?: any
}
export const initialProductsState: ReducerState = { loading: false }

const productReducer = (state = initialProductsState, action): ReducerState => {
  switch (action.type) {
    case 'GET_SINGLE_PRODUCT_REQUEST':
      return { ...state, loading: true }
    case 'GET_SINGLE_PRODUCT_SUCCESS':
      return { ...state, loading: false, item: action.product }
    case 'GET_SINGLE_PRODUCT_FAILURE':
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export default productReducer
