import { ACTIONS } from '../actions/user'

import { Order } from '../../interfaces/common/contract'

export interface UserReducerState {
  loading: boolean
  loginInProgress: boolean
  cardUpdated?: boolean
  userVerified?: boolean
  email?: any
  user?: any
  isLoggedIn?: boolean
  orders?: Order[]
  order?: Order
  cards?: any
  balance?: any
  ghostUser?: any
  error?: any
}
export const initialProductsState: UserReducerState = {
  loading: false,
  loginInProgress: false,
  isLoggedIn: false,
}

const userReducer = (
  state = initialProductsState,
  action
): UserReducerState => {
  switch (action.type) {
    case ACTIONS.SAVE_USER_REQUEST:
    case ACTIONS.REGISTER_USER_REQUEST:
    case ACTIONS.GET_USER_ORDERS_REQUEST:
    case ACTIONS.GET_USER_ORDER_BY_TOKEN_REQUEST:
    case ACTIONS.PUT_USER_REQUEST:
    case ACTIONS.PUT_USER_PASSWORD_REQUEST:
    case ACTIONS.FORGET_PASSWORD_REQUEST:
    case ACTIONS.GET_USER_REQUEST:
    case ACTIONS.VERIFY_USER_REQUEST:
    case ACTIONS.RESET_PASSWORD_REQUEST:
    case ACTIONS.PUT_USER_ORDER_REQUEST:
      return { ...state, loading: true }
    case ACTIONS.LOGIN_USER_REQUEST:
      return { ...state, loginInProgress: true }
    case ACTIONS.GET_USER_CARDS_REQUEST:
    case ACTIONS.POST_USER_CARD_REQUEST:
      return { ...state, loading: true, cardUpdated: false }
    case ACTIONS.VERIFY_USER_SUCCESS:
      return { ...state, loading: false, userVerified: true }
    case ACTIONS.SAVE_USER_SUCCESS:
      return { ...state, loading: false, email: action.email }
    case ACTIONS.REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.token,
        isLoggedIn: true,
      }
    case ACTIONS.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loginInProgress: false,
        user: action.user,
        isLoggedIn: true,
      }
    case ACTIONS.PUT_USER_SUCCESS:
    case ACTIONS.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        isLoggedIn: true,
      }
    case ACTIONS.LOGOUT_USER_SUCCESS:
      return { ...state, isLoggedIn: false, user: null }
    case ACTIONS.GET_USER_ORDERS_SUCCESS:
      return { ...state, loading: false, orders: action.orders }
    case ACTIONS.GET_USER_ORDER_BY_TOKEN_SUCCESS:
      return { ...state, loading: false, order: action.orders }
    case ACTIONS.GET_USER_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        cardUpdated: false,
        cards: action.cards,
      }
    case ACTIONS.GET_USER_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.balance,
      }
    case ACTIONS.POST_USER_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        cardUpdated: true,
        cards: action.cards,
      }
    case ACTIONS.SAVE_GHOST_USER:
      return { ...state, loading: false, ghostUser: action.ghostUserData }
    case ACTIONS.PUT_USER_PASSWORD_SUCCESS:
    case ACTIONS.RESET_PASSWORD_SUCCESS:
    case ACTIONS.FORGET_PASSWORD_SUCCESS:
    case ACTIONS.PUT_USER_ORDER_SUCCESS:
    case ACTIONS.LOGIN_USER_FAILURE:
    case ACTIONS.REGISTER_USER_FAILURE:
    case ACTIONS.PUT_USER_FAILURE:
    case ACTIONS.GET_USER_ORDERS_FAILURE:
    case ACTIONS.GET_USER_ORDER_BY_TOKEN_FAILURE:
    case ACTIONS.FORGET_PASSWORD_FAILURE:
    case ACTIONS.GET_USER_FAILURE:
    case ACTIONS.RESET_PASSWORD_FAILURE:
    case ACTIONS.PUT_USER_ORDER_FAILURE:
    case ACTIONS.GET_USER_CARDS_FAILURE:
    case ACTIONS.POST_USER_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.err,
      }
    case ACTIONS.VERIFY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        userVerified: false,
        error: action.err,
      }
    case ACTIONS.DELETE_USER_SUCCESS:
    case ACTIONS.DELETE_GHOST_USER:
      return { loading: false, loginInProgress: false }
    default:
      return state
  }
}
export default userReducer
