import { PrismicAccountData } from '../../interfaces/common/prismicAccount'
import { PrismicBasketData } from '../../interfaces/common/prismicBasket'
import { PrismicFunnelData } from '../../interfaces/common/prismicFunnel'
import { PrismicGeneralData } from '../../interfaces/common/prismicGeneralConfig'
import { PrismicProductData } from '../../interfaces/common/prismicProduct'

export interface PrismicStore {
  general?: PrismicGeneralData
  account?: PrismicAccountData
  funnel?: PrismicFunnelData
  basket?: PrismicBasketData
  prismicProduct?: PrismicProductData
}

const initialState = {
  general: undefined,
  account: undefined,
  funnel: undefined,
  prismicProduct: undefined,
}

const prismicReducer = (
  state: PrismicStore = initialState,
  action
): PrismicStore => {
  switch (action.type) {
    case 'SAVE_PRISMIC_DOCUMENTS':
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}

export default prismicReducer
