import { Contract } from '../../interfaces/common/contract'

interface ReducerState {
  loading: boolean
  items?: Contract[]
  error?: any
}

export const initialContractState: ReducerState = { loading: false }

const contractReducer = (
  state = initialContractState,
  action
): ReducerState => {
  switch (action.type) {
    case 'GET_IN_PROGRESS_CONTRACT_CSUB_REQUEST':
    case 'GET_IN_PROGRESS_CONTRACT_SUB_REQUEST':
      return { ...state, loading: true }
    case 'GET_IN_PROGRESS_CONTRACT_CSUB_SUCCESS':
    case 'GET_IN_PROGRESS_CONTRACT_SUB_SUCCESS':
      return { ...state, loading: false, items: action.currentContract }
    case 'GET_IN_PROGRESS_CONTRACT_CSUB_FAILURE':
    case 'GET_IN_PROGRESS_CONTRACT_SUB_FAILURE':
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export default contractReducer
