interface ReducerState {
  showBasketDetails: boolean
  noMixedBasketModalIsOpen: boolean
}

const initialState: ReducerState = {
  showBasketDetails: false,
  noMixedBasketModalIsOpen: false,
}

const basketReducer = (state = initialState, action): ReducerState => {
  switch (action.type) {
    case 'SET_SHOW_BASKET':
      return {
        ...state,
        showBasketDetails: action.isOpen,
      }
    case 'SET_OPEN_NO_MIXED_BASKET_MODAL':
      return {
        ...state,
        noMixedBasketModalIsOpen: action.isOpen,
      }
    default:
      return state
  }
}

export default basketReducer
