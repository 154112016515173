import { ProductVariant } from '../../../interfaces/products/shop'

export const ProductVariantAdapter = (variant) => {
  const productVariant: Partial<ProductVariant> = {
    code: variant?.code,
    name: variant?.name,
    sku: variant?.sku,
    price: { current: variant?.channelPricings?.WEB.price },
    purchase: variant?.channelPricings?.WEB?.purchase,
    purchasePrice: variant?.channelPricings?.WEB?.purchaseprice,
    maxStock: variant?.maxStock,
    size: variant?.newSize?.name,
  }

  if (variant?.optionValues[0]) {
    productVariant.axis = [variant?.optionValues[0]?.code]
    productVariant.nameAxis = Object.defineProperty(
      {},
      variant?.optionValues[0]?.code,
      {
        value: variant?.optionValues[0]?.code,
      }
    )
  }

  return productVariant as ProductVariant
}
