import axiosWrapper, { axiosRMSWrapper } from '../helpers/api'
import { RMSRequestUrl, EMethods, ERequestUrl } from '../helpers/requests'

export const postTakeBackInfo = (data) =>
  axiosWrapper({
    method: EMethods.post,
    url: ERequestUrl.postTakeBackInfo,
    data: data,
  })

export const getTakeBackRef = (data) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.takeBackRef,
    uid: data,
  })

export const getTakeBackProduct = (id: number) => {
  return axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.takeBackProduct,
    uid: id.toString(),
  })
}

export const getTakeBackStock = (data, warehouseId) =>
  axiosRMSWrapper({
    method: EMethods.get,
    url: RMSRequestUrl.takeBackStock,
    params: { ...data, warehouseId: warehouseId },
  })

export const getUserTakeBackProducts = (data) =>
  axiosRMSWrapper({
    method: EMethods.get,
    url: RMSRequestUrl.userTakeBackProducts,
    params: data,
  })

export const getRightOfWithdrawalInfo = (id: string) =>
  axiosRMSWrapper({
    method: EMethods.get,
    url: RMSRequestUrl.rightOfWithdrawal,
    uid: id + '/right-of-withdrawal',
  })
