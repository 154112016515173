import productsReducer from './productsReducer'
import taxonsReducer from './taxonsReducer'
import cartReducer from './cartReducer'
import productReducer from './singleProductReducer'
import userReducer from './userReducer'
import checkoutReducer from './checkoutReducer'
import pickingPlaceReducer from './pickingPlaceReducer'
import packsReducer from './packsReducer'
import taxonPackReducer from './taxonPackReducer'
import addressUserReducer from './addressUserReducer'
import stockReducer from './stockReducer'
import promotionsReducer from './promotionsReducer'
import inStoreReducer from './inStoreReducer'
import orderReducer from './orderReducer'
import reviewsReducer from './reviewsReducer'
import parametersReducer from './parametersReducer'
import contractReducer from './contractReducer'
import inputAddressReducer from './addressReducer'
import SSOReducer from './SSOreducer'
import takeBackReducer from './takeBackReducer'
import { combineReducers } from 'redux'
import basketReducer from './basketReducer'
import prismicReducer from './prismicReducer'

const rootReducer = combineReducers({
  products: productsReducer,
  pickingPlace: pickingPlaceReducer,
  taxons: taxonsReducer,
  stores: inStoreReducer,
  cart: cartReducer,
  addressUser: addressUserReducer,
  product: productReducer,
  stock: stockReducer,
  contract: contractReducer,
  parameters: parametersReducer,
  promotions: promotionsReducer,
  user: userReducer,
  checkout: checkoutReducer,
  reviews: reviewsReducer,
  packs: packsReducer,
  taxonPack: taxonPackReducer,
  returnProducts: orderReducer,
  inputAddress: inputAddressReducer,
  SSOdata: SSOReducer,
  takeBack: takeBackReducer,
  basket: basketReducer,
  prismic: prismicReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
