import * as takeBackService from '../services/takeBack'

export const ACTIONS = {
  POST_TAKE_BACK_INFO_REQUEST: 'POST_TAKE_BACK_INFO_REQUEST',
  POST_TAKE_BACK_INFO_SUCCESS: 'POST_TAKE_BACK_INFO_SUCCESS',
  POST_TAKE_BACK_INFO_FAILURE: 'POST_TAKE_BACK_INFO_FAILURE',
  GET_TAKE_BACK_REFERENCE_REQUEST: 'GET_TAKE_BACK_REFERENCE_REQUEST',
  GET_TAKE_BACK_REFERENCE_SUCCESS: 'GET_TAKE_BACK_REFERENCE_SUCCESS',
  GET_TAKE_BACK_REFERENCE_FAILURE: 'GET_TAKE_BACK_REFERENCE_FAILURE',
  GET_TAKE_BACK_ID_REQUEST: 'GET_TAKE_BACK_ID_REQUEST',
  GET_TAKE_BACK_ID_SUCCESS: 'GET_TAKE_BACK_ID_SUCCESS',
  GET_TAKE_BACK_ID_FAILURE: 'GET_TAKE_BACK_ID_FAILURE',
  GET_TAKE_BACK_STOCK_REQUEST: 'GET_TAKE_BACK_STOCK_REQUEST',
  GET_TAKE_BACK_STOCK_SUCCESS: 'GET_TAKE_BACK_STOCK_SUCCESS',
  GET_TAKE_BACK_STOCK_FAILURE: 'GET_TAKE_BACK_STOCK_FAILURE',
  GET_USER_TAKE_BACK_PRODUCTS_REQUEST: 'GET_USER_TAKE_BACK_PRODUCTS_REQUEST',
  GET_USER_TAKE_BACK_PRODUCTS_SUCCESS: 'GET_USER_TAKE_BACK_PRODUCTS_SUCCESS',
  GET_USER_TAKE_BACK_PRODUCTS_FAILURE: 'GET_USER_TAKE_BACK_PRODUCTS_FAILURE',
  GET_RIGHT_OF_WITHDRAWAL_INFO_REQUEST: 'GET_RIGHT_OF_WITHDRAWAL_INFO_REQUEST',
  GET_RIGHT_OF_WITHDRAWAL_INFO_SUCCESS: 'GET_RIGHT_OF_WITHDRAWAL_INFO_SUCCESS',
  GET_RIGHT_OF_WITHDRAWAL_INFO_FAILURE: 'GET_RIGHT_OF_WITHDRAWAL_INFO_FAILURE',
}

export const POST_TAKE_BACK_INFO_REQUEST = () => ({
  type: ACTIONS.POST_TAKE_BACK_INFO_REQUEST,
})
export const POST_TAKE_BACK_INFO_SUCCESS = (response) => ({
  type: ACTIONS.POST_TAKE_BACK_INFO_SUCCESS,
  response,
})
export const POST_TAKE_BACK_INFO_FAILURE = (err) => ({
  type: ACTIONS.POST_TAKE_BACK_INFO_FAILURE,
  err,
})

export function postTakeBackInfo(data) {
  return (dispatch) => {
    dispatch(POST_TAKE_BACK_INFO_REQUEST())

    return takeBackService.postTakeBackInfo(data).then(
      (response) => dispatch(POST_TAKE_BACK_INFO_SUCCESS(response)),
      (err) => dispatch(POST_TAKE_BACK_INFO_FAILURE(err))
    )
  }
}

export const GET_TAKE_BACK_REFERENCE_REQUEST = () => ({
  type: ACTIONS.GET_TAKE_BACK_REFERENCE_REQUEST,
})
export const GET_TAKE_BACK_REFERENCE_SUCCESS = (response) => ({
  type: ACTIONS.GET_TAKE_BACK_REFERENCE_SUCCESS,
  response,
})
export const GET_TAKE_BACK_REFERENCE_FAILURE = (err) => ({
  type: ACTIONS.GET_TAKE_BACK_REFERENCE_FAILURE,
  err,
})

export function getTakeBackRef(data) {
  return (dispatch) => {
    dispatch(GET_TAKE_BACK_REFERENCE_REQUEST())

    return takeBackService.getTakeBackRef(data).then(
      (response) => dispatch(GET_TAKE_BACK_REFERENCE_SUCCESS(response)),
      (err) => dispatch(GET_TAKE_BACK_REFERENCE_FAILURE(err))
    )
  }
}

export const GET_TAKE_BACK_ID_REQUEST = () => ({
  type: ACTIONS.GET_TAKE_BACK_ID_REQUEST,
})
export const GET_TAKE_BACK_ID_SUCCESS = (response) => ({
  type: ACTIONS.GET_TAKE_BACK_ID_SUCCESS,
  response,
})
export const GET_TAKE_BACK_ID_FAILURE = (err) => ({
  type: ACTIONS.GET_TAKE_BACK_ID_FAILURE,
  err,
})

export function getTakeBackProduct(id: number) {
  return (dispatch) => {
    dispatch(GET_TAKE_BACK_ID_REQUEST())

    return takeBackService.getTakeBackProduct(id).then(
      (response) => dispatch(GET_TAKE_BACK_ID_SUCCESS(response)),
      (err) => dispatch(GET_TAKE_BACK_ID_FAILURE(err))
    )
  }
}

export const GET_TAKE_BACK_STOCK_REQUEST = () => ({
  type: ACTIONS.GET_TAKE_BACK_STOCK_REQUEST,
})
export const GET_TAKE_BACK_STOCK_SUCCESS = (response) => ({
  type: ACTIONS.GET_TAKE_BACK_STOCK_SUCCESS,
  response,
})
export const GET_TAKE_BACK_STOCK_FAILURE = (err) => ({
  type: ACTIONS.GET_TAKE_BACK_STOCK_FAILURE,
  err,
})

export function getTakeBackStock(data, warehouseId?: number) {
  return (dispatch) => {
    dispatch(GET_TAKE_BACK_STOCK_REQUEST())

    return takeBackService.getTakeBackStock(data, warehouseId).then(
      (response) => dispatch(GET_TAKE_BACK_STOCK_SUCCESS(response)),
      (err) => dispatch(GET_TAKE_BACK_STOCK_FAILURE(err))
    )
  }
}

export const GET_USER_TAKE_BACK_PRODUCTS_REQUEST = () => ({
  type: ACTIONS.GET_USER_TAKE_BACK_PRODUCTS_REQUEST,
})
export const GET_USER_TAKE_BACK_PRODUCTS_SUCCESS = (response) => ({
  type: ACTIONS.GET_USER_TAKE_BACK_PRODUCTS_SUCCESS,
  response,
})
export const GET_USER_TAKE_BACK_PRODUCTS_FAILURE = (err) => ({
  type: ACTIONS.GET_USER_TAKE_BACK_PRODUCTS_FAILURE,
  err,
})

export function getUserTakeBackProducts(data) {
  return (dispatch) => {
    dispatch(GET_USER_TAKE_BACK_PRODUCTS_REQUEST())

    return takeBackService.getUserTakeBackProducts(data).then(
      (response) => dispatch(GET_USER_TAKE_BACK_PRODUCTS_SUCCESS(response)),
      (err) => dispatch(GET_USER_TAKE_BACK_PRODUCTS_FAILURE(err))
    )
  }
}

export const GET_RIGHT_OF_WITHDRAWAL_INFO_REQUEST = () => ({
  type: ACTIONS.GET_RIGHT_OF_WITHDRAWAL_INFO_REQUEST,
})

export const GET_RIGHT_OF_WITHDRAWAL_INFO_SUCCESS = (response) => ({
  type: ACTIONS.GET_RIGHT_OF_WITHDRAWAL_INFO_SUCCESS,
  response,
})
export const GET_RIGHT_OF_WITHDRAWAL_INFO_FAILURE = (err) => ({
  type: ACTIONS.GET_RIGHT_OF_WITHDRAWAL_INFO_FAILURE,
  err,
})

export function getRightOfWithdrawalInfo(id: string) {
  return (dispatch) => {
    dispatch(GET_RIGHT_OF_WITHDRAWAL_INFO_REQUEST())

    return takeBackService.getRightOfWithdrawalInfo(id).then(
      (response) => dispatch(GET_RIGHT_OF_WITHDRAWAL_INFO_SUCCESS(response)),
      (err) => dispatch(GET_RIGHT_OF_WITHDRAWAL_INFO_FAILURE(err))
    )
  }
}
