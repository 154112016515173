export const disableRegister = process.env.DISABLE_REGISTER

export const DEBUG = '/confirmation?debug=true'

export const isDateToDate = process.env.STORE_TYPE === 'date_to_date'
export const isFlex = process.env.STORE_TYPE === 'subscription'
export const isClassicSub = process.env.STORE_TYPE === 'classic_subscription'
export const isSubscription = isFlex || isClassicSub

export const SAVED_START_DATE = 'savedStartDate'
export const SAVED_END_DATE = 'savedEndDate'

export const enableHalfDay = process.env.ENABLE_HALF_DAY === 'true'

export const DATADOG_KEY = 'pube26c9972b5d28d5ea349653bf9915589'

export const PRICE_WITHOUT_DISCOUNT = 'price without discount'
export const DISCOUNTED_PRICE_ONLY = 'discounted price only'
export const DISCOUNTED_PRICE_WITH_DETAILS = 'discounted price with details'

export const minSubDuration = process.env.BRAND_MIN_SUB_DAYS

export const isLoginSSO = process.env.SSO_LOGIN
export const isSSO = process.env.SSO_TYPE !== undefined

export const RMSDateFormat = 'YYYY-MM-DD'
export const SyliusDateFormat = 'DD/MM/YYYY'

export const byPassPayment =
  process.env.BYPASS_PAYMENT === 'true' ? true : false
export const byPassPassword = process.env.BYPASS_PASSWORD

export const ORDER_ITEM_TYPE = {
  RENTAL: 'RENTAL',
  PURCHASE: 'PURCHASE',
  TRYING: 'TRYING',
  SECOND_HAND: 'SECOND_HAND',
  MIXED: 'MIXED',
}

export const BY_PASS_TYPE = {
  CUSTOMER: 'CUSTOMER',
  OPERATOR: 'OPERATOR',
}

export const currentVersion = process.env.VERSION

export const secondHandCarrierId = process.env.CARRIER_ID

export const isSecondHandOnly = process.env.IS_SECOND_HAND_ONLY

export enum ORDER_STATE {
  CANCELLED = 'cancelled',
  COMING = 'coming',
  RETURN = 'return',
  FINISHED = 'finished',
}

export const enum PAYMENT_ACCORDION {
  CARD = 'card',
  PAYPAL = 'paypal',
}
