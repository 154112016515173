import * as TaxonsService from '../services/taxons'
import { TaxonGroup } from '../../typings/base'

export const ACTIONS = {
  GET_TAXONS_REQUEST: 'GET_TAXONS_REQUEST',
  GET_TAXONS_SUCCESS: 'GET_TAXONS_SUCCESS',
  GET_TAXONS_FAILURE: 'GET_TAXONS_FAILURE',
  GET_TAXONS_ATTRIBUTES_REQUEST: 'GET_TAXONS_ATTRIBUTES_REQUEST',
  GET_TAXONS_ATTRIBUTES_SUCCESS: 'GET_TAXONS_ATTRIBUTES_SUCCESS',
  GET_TAXONS_ATTRIBUTES_FAILURE: 'GET_TAXONS_ATTRIBUTES_FAILURE',
  GET_TAXONS_SIZES_REQUEST: 'GET_TAXONS_SIZES_REQUEST',
  GET_TAXONS_SIZES_SUCCESS: 'GET_TAXONS_SIZES_SUCCESS',
  GET_TAXONS_SIZES_FAILURE: 'GET_TAXONS_SIZES_FAILURE',
}

export const GET_TAXONS_REQUEST = () => ({
  type: ACTIONS.GET_TAXONS_REQUEST,
})

export const GET_TAXONS_SUCCESS = (taxons: TaxonGroup) => ({
  type: ACTIONS.GET_TAXONS_SUCCESS,
  taxons,
})

export const GET_TAXONS_FAILURE = (err) => ({
  type: ACTIONS.GET_TAXONS_FAILURE,
  err,
})

export function getTaxons() {
  return (dispatch) => {
    dispatch(GET_TAXONS_REQUEST())
    return TaxonsService.getTaxons().then(
      (response) => dispatch(GET_TAXONS_SUCCESS(response)),
      (err) => dispatch(GET_TAXONS_FAILURE(err))
    )
  }
}

export const GET_TAXONS_ATTRIBUTES_REQUEST = () => ({
  type: ACTIONS.GET_TAXONS_ATTRIBUTES_REQUEST,
})

export const GET_TAXONS_ATTRIBUTES_SUCCESS = (attributes) => ({
  type: ACTIONS.GET_TAXONS_ATTRIBUTES_SUCCESS,
  attributes,
})

export const GET_TAXONS_ATTRIBUTES_FAILURE = (err) => ({
  type: ACTIONS.GET_TAXONS_ATTRIBUTES_FAILURE,
  err,
})

export function getTaxonsAttributes(taxonSlug) {
  return (dispatch) => {
    dispatch(GET_TAXONS_ATTRIBUTES_REQUEST())
    return TaxonsService.getTaxonsAttributes(taxonSlug).then(
      (response) => dispatch(GET_TAXONS_ATTRIBUTES_SUCCESS(response)),
      (err) => dispatch(GET_TAXONS_ATTRIBUTES_FAILURE(err))
    )
  }
}

export const GET_TAXONS_SIZES_REQUEST = () => ({
  type: ACTIONS.GET_TAXONS_SIZES_REQUEST,
})

export const GET_TAXONS_SIZES_SUCCESS = (sizes) => ({
  type: ACTIONS.GET_TAXONS_SIZES_SUCCESS,
  sizes,
})

export const GET_TAXONS_SIZES_FAILURE = (err) => ({
  type: ACTIONS.GET_TAXONS_SIZES_FAILURE,
  err,
})

export function getTaxonsSizes(taxonCode) {
  return (dispatch) => {
    dispatch(GET_TAXONS_SIZES_REQUEST())
    return TaxonsService.getTaxonsSizes(taxonCode).then(
      (response) => dispatch(GET_TAXONS_SIZES_SUCCESS(response)),
      (err) => dispatch(GET_TAXONS_SIZES_FAILURE(err))
    )
  }
}
