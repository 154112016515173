import dayjs from 'dayjs'
import { CartContent, GAEvent, ProductInCart } from '../typings/base'
import { isDateToDate, isSecondHandOnly, isSubscription } from './constants'
import { numberToPrice } from './utils'

type WindowWithDataLayer = Window & {
  gtag: Function
}

declare const window: WindowWithDataLayer

export const isChalhoub = process.env.STORE === 'chalhoub'

export const clearItemVariantFromGAParamsIfNotChalhoub = (
  gaParams: any
): any => {
  const paramsCopy = { ...gaParams }
  if (!isChalhoub) {
    for (const item of paramsCopy.items) {
      delete item['item_variant']
    }
  }

  return paramsCopy
}

export const tracker = (eventName: string, params: Record<string, any>) => {
  if (
    process.env.NEXT_PUBLIC_GA_ID &&
    typeof window !== 'undefined' &&
    !!window.gtag
  ) {
    window.gtag('event', eventName, params)
    return true
  }

  return false
}

export function hangleGACartEventWithSingleProduct(
  cartItem: CartContent,
  productInCart: ProductInCart,
  gaEvent: GAEvent,
  puid?: string,
  quantity = 1
) {
  const { currency } = cartItem
  const { product, units, withTryingOn } = productInCart

  let price = 0
  if (isSecondHandOnly) {
    price = numberToPrice(units.find((unit) => unit.puid === puid).price)
  } else if (isDateToDate) {
    if (withTryingOn) {
      price = product.variants[0].tryingOnPrice
    } else {
      const { startLeasingDate, endLeasingDate } = cartItem
      const numberOfDays =
        dayjs(endLeasingDate, 'DD/MM/YYYY').diff(
          dayjs(startLeasingDate, 'DD/MM/YYYY'),
          'day'
        ) + 1
      const priceByDay = product.variants[0].price.current
      price = numberToPrice(priceByDay * numberOfDays)
    }
  }

  const firstVariant = Object.values(product.variants)[0]
  const gaParams = {
    currency,
    value: price * quantity,
    items: [
      {
        ['item_name']: product.name,
        ['item_id']: isChalhoub ? firstVariant.sku : product.code,
        ['item_variant']: isChalhoub && firstVariant.code,
        price,
        quantity,
      },
    ],
  }

  const cleanGAParams = clearItemVariantFromGAParamsIfNotChalhoub(gaParams)
  tracker(gaEvent, cleanGAParams)
}

export function hangleGACartEventWithMultipleProducts(
  gaEvent: GAEvent,
  cartItem: CartContent
) {
  const { totals, currency, items } = cartItem

  const totalPrice = isSubscription
    ? numberToPrice(totals.totalWithCart)
    : numberToPrice(totals.total)

  const gaParams = {
    currency,
    value: totalPrice,
    items: items.map(({ product, quantity, total }) => {
      const firstVariant = Object.values(product.variants)[0]

      return {
        ['item_name']: product.name,
        ['item_id']: isChalhoub ? firstVariant.sku : product.code,
        ['item_variant']: isChalhoub && firstVariant.code,
        price: numberToPrice(total),
        quantity,
      }
    }),
  }

  const cleanGAParams = clearItemVariantFromGAParamsIfNotChalhoub(gaParams)
  tracker(gaEvent, cleanGAParams)
}
