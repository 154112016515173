import { useEffect } from 'react'

const useCookiebot = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = `https://consent.cookiebot.com/uc.js?cbid=${process.env.COOKIEBOT_DOMAIN_ID}`
    script.async = true

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [])
}

export default useCookiebot
