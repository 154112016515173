import { PackAdapter } from '../adapter/packs/packAdapter'

interface ReducerState {
  loading: boolean
  items?: any[]
  origin?: any
  error?: any
}

export const initialProductsState: ReducerState = { loading: false }

const packsReducer = (state = initialProductsState, action): ReducerState => {
  switch (action.type) {
    case 'GET_PACKS_BY_PACK_TAXON_ID_REQUEST':
      return { ...state, loading: true }
    case 'GET_PACKS_BY_PACK_TAXON_ID_SUCCESS':
      return {
        ...state,
        loading: false,
        items: action.packs.map((pack) => PackAdapter(pack)),
      }
    case 'SAVE_CURRENT_PACK':
      return {
        ...state,
        loading: false,
        items: action.currentPack.map((currentPack) =>
          PackAdapter(currentPack)
        ),
      }
    case 'SAVE_PACK_ORIGIN':
      return { ...state, loading: false, origin: action.origin }
    case 'DELETE_PACK_ORIGIN': {
      const temp = { ...state }
      delete temp['origin']
      return temp
    }
    case 'GET_PACKS_BY_PACK_TAXON_ID_FAILURE':
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export default packsReducer
