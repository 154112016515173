interface ReducerState {
  loading: boolean
  pdfResponse?: any
  error?: any
}

export const initialProductsState: ReducerState = { loading: false }

const checkoutReducer = (
  state = initialProductsState,
  action
): ReducerState => {
  switch (action.type) {
    case 'POST_CHECKOUT_REQUEST':
      return { ...state, loading: true }
    case 'POST_CHECKOUT_SUCCESS':
      return { ...state, loading: false, pdfResponse: action.data }
    case 'POST_CHECKOUT_FAILURE':
      return { ...state, loading: false, error: action.err }
    default:
      return state
  }
}

export default checkoutReducer
