export const initialProductsState = { loading: false }

const pickingplaceReducer = (state = initialProductsState, action) => {
  switch (action.type) {
    case 'GET_PICKING_PLACE_REQUEST':
      return { ...state, loading: true }
    case 'GET_PICKING_PLACE_SUCCESS':
      return { ...state, loading: false, items: action.pickingPlace }
    case 'GET_PICKING_PLACE_FAILURE':
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export default pickingplaceReducer
