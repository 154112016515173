export enum AddressType {
  AREA = 'area',
}

export interface SearchAddress {
  hasHouseNumber: boolean
  streetNumber: number
  street: string
  postcode: string
  city: string
  country_code: string
  country: string
}

export interface GoogleMapAddress {
  country: string
  streetNumber: number
  street: string
  city: string
  postcode: string
  details: string
}

export interface AreaAddress {
  street: string
  city: string
  area: string
}

export type InputAddress = GoogleMapAddress | AreaAddress

export interface PayloadAddress {
  countryCode: string
  street: string
  details: string
  city: string
  postcode: string
}

export interface AddressStore {
  isPanelOpen: boolean
  isValid: boolean
  payload: PayloadAddress
  search: SearchAddress
  input: InputAddress
}

export const initialState = {
  search: undefined,
  payload: undefined,
  input: undefined,
  isValid: false,
  isPanelOpen: false,
}

const inputAddressReducer = (state: AddressStore = initialState, action) => {
  switch (action.type) {
    case 'SAVE_PAYLOAD_ADDRESS':
      return {
        ...state,
        payload: action.payload,
        isValid: action.isValid,
      }
    case 'SAVE_SEARCH_ADDRESS':
      return {
        ...state,
        search: action.search,
      }
    case 'SAVE_INPUT_ADDRESS':
      return {
        ...state,
        isPanelOpen: action.isPanelOpen,
        input: action.input,
      }
    case 'RESET_INPUT_ADDRESS':
      return initialState
    default:
      return { ...state, search: undefined }
  }
}

export default inputAddressReducer
