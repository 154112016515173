export const initialProductsState = {
  loading: false,
  hasReturned: false,
  items: [],
}

const promotionsReducer = (state = initialProductsState, action) => {
  const reviews = { ...state }
  switch (action.type) {
    case 'GET_REVIEWS_USER_REQUEST':
    case 'POST_REVIEWS_USER_REQUEST':
      return { ...state, loading: true }
    case 'GET_REVIEWS_USER_SUCCESS':
      if (
        action.reviews.length > 0 &&
        !reviews.items.find((item) => item.sku === action.reviews[0].sku)
      ) {
        reviews.items.push(action.reviews[0])
      }
      return { ...state, loading: false, items: reviews.items }
    case 'POST_REVIEWS_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        items: action.response,
        hasReturned: true,
      }
    case 'GET_REVIEWS_USER_FAILURE':
    case 'POST_REVIEWS_USER_FAILURE':
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export default promotionsReducer
