import React, { createContext, useState, useEffect, ReactNode } from 'react'
import { useRouter } from 'next/router'

export interface PreviousUrlContextType {
  prevUrl: string
  slugs: string[]
}

export interface PreviousUrlProviderProps {
  children: ReactNode
}

export const PreviousUrlContext = createContext<
  PreviousUrlContextType | undefined
>(undefined)

export const PreviousUrlProvider = ({ children }: PreviousUrlProviderProps) => {
  const [prevUrlState, setPrevUrlState] = useState<PreviousUrlContextType>({
    prevUrl: '',
    slugs: [],
  })

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      if (typeof window !== 'undefined') {
        const prevUrl = window.location.href
        const path = prevUrl.split(window.location.origin)[1]
        const slugs = path
          .split('/')
          .filter(
            (slug) => slug !== '' && router.locales.includes(slug) === false
          )

        setPrevUrlState({
          prevUrl,
          slugs,
        })
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  return (
    <PreviousUrlContext.Provider value={prevUrlState}>
      {children}
    </PreviousUrlContext.Provider>
  )
}
