interface ReducerState {
  loading: boolean
  items: {
    token_type: string
    expires_in: number | string
    access_token: string
    scope: string
    refresh_token: string
    id_token: string
  }
  error?: any
}

export const initialProductsState: ReducerState = {
  loading: false,
  items: {
    token_type: '',
    expires_in: '',
    access_token: '',
    scope: '',
    refresh_token: '',
    id_token: '',
  },
}

const SSOReducer = (state = initialProductsState, action): ReducerState => {
  switch (action.type) {
    case 'GET_TOKEN_REQUEST':
      return { ...state, loading: true }
    case 'GET_TOKEN_SUCCESS':
      return { ...state, loading: false, items: action.token }
    case 'GET_TOKEN_FAILURE':
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}

export default SSOReducer
