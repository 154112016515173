import { ACTIONS } from '../actions/products'
import { Image } from '@/typings/base'

export interface StockReducerState {
  loading?: boolean
  stock?: StockElement[]
  loadingBasket?: boolean
  stockBasket?: StockElement[]
  error?: any
}
//@TODO : reuse types
export interface StockElement {
  lizeeSKU?: string
  quantity?: number
  inStoreQuantity?: number
  warehouseQuantity?: number
  error?: string
  secondHand?: SecondHand[]
}

export interface SecondHand {
  quality?: string
  quantity?: number
  images?: Image[]
}

export const initialProductsState: StockReducerState = {
  loading: true,
  stock: [],
}

const stockReducer = (
  state = initialProductsState,
  action
): StockReducerState => {
  switch (action.type) {
    case ACTIONS.GET_STOCK_REQUEST:
      return { ...state, loading: true }
    case ACTIONS.GET_STOCK_BASKET_REQUEST:
      return { ...state, loadingBasket: true }
    case ACTIONS.GET_STOCK_SUCCESS: {
      const currentStock = state.stock
      const incomingStock = action.stock
      // mix currentStock and incoming stock answer
      const cumulatedStock = [...currentStock, ...incomingStock]
      // Remove doublons
      const uniqueStock = cumulatedStock.filter(
        (obj, index) =>
          cumulatedStock.findIndex((item) => item.lizeeSKU === obj.lizeeSKU) ===
          index
      )
      // Still we check if a SKU in the answer has a different quantity
      uniqueStock.forEach((item) => {
        incomingStock.map((obj) => {
          // We detect that a quantity has changed
          if (
            item.lizeeSKU === obj.lizeeSKU &&
            item.quantity !== obj.quantity
          ) {
            const i = cumulatedStock.findIndex(
              (currentItem) => currentItem.lizeeSKU === item.lizeeSKU
            )
            // Update specific SKU quantity
            cumulatedStock[i].quantity = obj.quantity
          }
        })
      })
      return { ...state, loading: false, stock: uniqueStock, error: undefined }
    }
    case ACTIONS.GET_STOCK_BASKET_SUCCESS:
      return {
        ...state,
        loadingBasket: false,
        stockBasket: action.stockBasket,
        error: undefined,
      }
    case ACTIONS.GET_STOCK_FAILURE:
    case ACTIONS.GET_STOCK_BASKET_FAILURE:
      return { ...state, loading: false, error: action.err?.code }
    default:
      return state
  }
}

export default stockReducer
