import { Taxon } from '../../typings/base'
import axiosWrapper from '../helpers/api'
import { ERequestUrl, EMethods } from '../helpers/requests'

export const getTaxons = (customLocale?: string) =>
  axiosWrapper({ method: EMethods.get, url: ERequestUrl.taxons, customLocale })

export const getTaxonsAttributes = (taxonSlug) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.taxonAttributes,
    uid: taxonSlug,
  })

export const getTaxonsSizes = (taxonCode) =>
  axiosWrapper({
    method: EMethods.get,
    url: ERequestUrl.taxonSizes,
    uid: taxonCode + '/sizes',
  })

export const getLocalesTaxons = async (locales: string[]) => {
  const taxonsMap: Record<string, Taxon[]> = {}

  for (const locale of locales) {
    const taxons = await getTaxons(locale)
    taxonsMap[locale] = taxons?.[0]?.children || []
  }

  return taxonsMap
}
