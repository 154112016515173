import axios from 'axios'
import { TAxiosWrapper } from './requests'
import path from 'path'
import i18next from 'i18next'
import { datadogLogs } from '@datadog/browser-logs'
import axiosRetry from 'axios-retry'

const prismicLocales = process.env.PRISMIC_LOCALES || ''
const apiDomain = process.env.API_DOMAIN || ''
const RMS_API = process.env.RMS_API_URL || ''
const RMS_PUBLIC_API_TOKEN = process.env.RMS_PUBLIC_API_TOKEN || ''

axios.interceptors.request.use(
  async (config) => {
    const retry = config['axios-retry']
    // The request has failed 3 times due to network issues
    // Make the request pass through the next.js api endpoint
    if (retry && retry.retryCount === 3) {
      const proxyRequestData = {
        data: config.data,
        method: config.method,
        headers: config.headers,
        auth: config.auth,
        url: config.url,
        params: config.params,
        // allows cookies to be sent
        withCredentials: true,
      }
      datadogLogs.logger.info(
        'Retrying request through nextjs proxy endpoint',
        proxyRequestData
      )

      return {
        ...config,
        method: 'POST',
        url: '/api/proxy',
        data: JSON.stringify(proxyRequestData),
      }
    }
    return config
  },
  (err) => {
    // Request failed to be sent (!!!)
    datadogLogs.logger.error('Request failed to be sent', err, err)
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(undefined, (err) => {
  // Only log if the retry attempts have been exhausted
  if (
    axios.isAxiosError(err) &&
    err?.config?.url?.includes('api/proxy') &&
    err?.config?.['axios-retry']?.retryCount === 3
  ) {
    datadogLogs.logger.error('Response failed', err, err)
  }
  return Promise.reject(err)
})

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  onRetry: (retryCount, error, config) => {
    datadogLogs.logger.info('Retrying request', { retryCount, config }, error)
  },
  retryCondition: (error) => {
    return error.code === 'ERR_NETWORK'
  },
})

const getLocale = (customLocale?: string) => {
  const selectedLocale = customLocale ?? i18next.language
  const locale = prismicLocales
    .split(',')
    .find((loc) => loc.startsWith(selectedLocale))

  if (!locale) {
    return selectedLocale
  }

  return `${selectedLocale}_${locale.substring(3, 5).toUpperCase()}`
}

const axiosWrapper = async <T = any>({
  method,
  url,
  headers,
  data,
  uid,
  params,
  customLocale,
  isV2 = false,
  hasLocale = true,
}: TAxiosWrapper) => {
  const syliusLocale = getLocale(customLocale)

  let apiResChar = ''
  if (isV2) {
    apiResChar = '&'
  } else {
    apiResChar = '?'
  }

  const domain = apiDomain
  const baseUrl = domain.endsWith('/') ? domain : `${domain}/`
  let fullUrl = `${baseUrl}${path.join(url, uid || '')}`

  if (hasLocale) {
    fullUrl = fullUrl + `${apiResChar}locale=${syliusLocale}`
  }

  try {
    // This version of datadog only runs in the browser, on server it's looking for `document`
    // Find a way to log requests made from server with dd-trace
    // Next.js has issues with dd-trace because it requiers Node.JS APIs
    // Possible workout: https://github.com/vercel/next.js/discussions/16600#discussioncomment-5003698
    datadogLogs.logger.info('Request to sylius', {
      method: method,
      origin_data: data,
      params: params,
      url: fullUrl,
    })
    // eslint-disable-next-line no-empty
  } catch (e) {}

  const response = await axios({
    method,
    url: fullUrl,
    headers: { ...headers },
    params,
    data,
    // allows cookies to be sent
    withCredentials: true,
  })

  try {
    datadogLogs.logger.info('Response from sylius', {
      method: method,
      origin_data: data,
      params: params,
      response_data: response.data,
      status: response.status,
      url: fullUrl,
    })
    // eslint-disable-next-line no-empty
  } catch (e) {}

  return (response.data.data ? response.data.data : response.data) as T
}

export default axiosWrapper

export const axiosRMSWrapper = async ({
  method,
  url,
  headers,
  params,
  uid,
  data,
}: TAxiosWrapper) => {
  const domain = RMS_API
  const baseUrl = domain.endsWith('/api') ? `${domain}/` : `${domain}/api/`
  const fullUrl = `${baseUrl}${path.join(url, uid || '')}`
  const locale = getLocale()

  const dataWithLocale = { ...data, locale }

  datadogLogs.logger.info('Request to RMS', {
    origin_data: dataWithLocale,
    url: fullUrl,
    method: method,
    params: params,
  })
  const response = await axios({
    method,
    url: fullUrl,
    headers: {
      'x-api-key': RMS_PUBLIC_API_TOKEN,
      'Content-Type': 'application/json',
      ...headers,
    },
    params,
    data: dataWithLocale,
  })
  datadogLogs.logger.info('Response from RMS', {
    method: method,
    origin_data: dataWithLocale,
    response_data: response.data,
    status_code: response.status,
    params: params,
    url: fullUrl,
  })
  return response.data.data ? response.data.data : response.data
}
