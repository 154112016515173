interface ReducerState {
  stores: any
  selectedStore?: any
  deleteOrigin?: any
}

export const initialState: ReducerState = { stores: {} }

const inStoreReducer = (state = initialState, action): ReducerState => {
  switch (action.type) {
    case 'SAVE_CHOSEN_STORE':
      return {
        ...state,
        selectedStore: action.selectedStore,
      }
    case 'DELETE_CHOSEN_STORE':
      return { ...state.stores, deleteOrigin: action.deleteOrigin }
    default:
      return state
  }
}

export default inStoreReducer
